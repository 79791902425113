import React, { memo } from 'react'
import { BackTop, Layout } from 'antd'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Footer from 'components/LayoutComponents/Footer'
import Breadcrumbs from 'components/LayoutComponents/Breadcrumbs'
import Settings from 'components/LayoutComponents/Settings'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const MainLayout = memo(({ children }) => {
  const settings = useSelector(state => (state.user && state.settings) || {})
  const { isBorderless, isSquaredBorders, isFixedWidth, isMenuShadow, isMenuTop } = settings
  return (
    <Layout className={styles.container}>
      {/*<BackTop />*/}
      <Menu />
      {/*<Settings />*/}
      <Layout>
        <Layout.Header>
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          {/*<Breadcrumbs />*/}
          <div className="utils__content">{children}</div>
        </Layout.Content>
        <Layout.Footer>
          <footer>
            <div className="utils__content">
              <Link to="/">COPYRIGHT EY 2020</Link>
              <div className="links">
                <a href="#">ABOUT SERIOUS GAMES</a>
                <a href="#">PRIVACY</a>
                <a href="#">TAKE A TOUR</a>
              </div>
            </div>
          </footer>
        </Layout.Footer>
      </Layout>
    </Layout>
  )
})
export default MainLayout
