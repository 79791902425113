import React from 'react'
import styles from './style.module.scss'
import MenuTop from "../Menu/MenuTop";

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <MenuTop />
        {/*<div className="mr-4">*/}
        {/*  <IssuesHistory />*/}
        {/*</div>*/}
        {/*<div className="mr-4">*/}
        {/*  <ProjectManagement />*/}
        {/*</div>*/}
        {/*<div className="mr-auto">*/}
        {/*  <LiveSearch />*/}
        {/*</div>*/}
        {/*<a*/}
        {/*  href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*  className="mr-4 d-none d-sm-inline"*/}
        {/*>*/}
        {/*  <Button type="danger">*/}
        {/*    <FormattedMessage id="topBar.buyNow" />*/}
        {/*  </Button>*/}
        {/*</a>*/}
        {/*<div className="mr-4">*/}
        {/*  <BitcoinPrice />*/}
        {/*</div>*/}
        {/*<div className="mr-4">*/}
        {/*  <LanguageSelector />*/}
        {/*</div>*/}
        {/*<div className="mr-4 ml-auto">*/}
        {/*  <HomeMenu />*/}
        {/*</div>*/}
        <div className={styles.welcome}>
          HI, HANNAH
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          HELP
          </div>

        {/* <ProfileMenu /> */}
      </div>
    )
  }
}

export default TopBar
