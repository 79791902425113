import React, { memo } from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'

const LoginLayout = memo(({ children }) => {
  return (
    <Layout className={styles.container}>
      <Layout.Header>
        <header>
          <div className="utils__content">
            <Link to="/">
              <img alt="EY" src="/resources/images/ey-serious-games-logo.png" />
            </Link>
            <div className="links">
              <a href="#">Help</a>
            </div>
          </div>
        </header>
      </Layout.Header>

      <Layout.Content>
        <div className="utils__content">{children}</div>
      </Layout.Content>
      <Layout.Footer>
        <footer>
          <div className="utils__content">
            <Link to="/">COPYRIGHT EY 2020</Link>
            <div className="links">
              <a href="#">ABOUT SERIOUS GAMES</a>
              <a href="#">PRIVACY</a>
              <a href="#">TAKE A TOUR</a>
            </div>
          </div>
        </footer>
      </Layout.Footer>
    </Layout>
  )
})

export default LoginLayout
