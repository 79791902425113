export async function getLeftMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      url: '/dashboard',
      // children: [
      //   {
      //     title: 'Dashboard',
      //     key: 'dashboard',
      //     url: '/dashboard',
      //   },
      // ]
    },
  ]
}
export async function getTopMenuData() {
  return [
    // {
    //   title: 'Dashboard',
    //   key: 'dashboard',
    //   url: '/dashboard',
    //   // children: [
    //   //   {
    //   //     title: 'Dashboard',
    //   //     key: 'dashboard',
    //   //     url: '/dashboard',
    //   //   },
    //   // ]
    // },
  ]
}
