import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import actions from './actions'
import users from '../../services/users.json'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = users.find(
    u => u.email.toLowerCase() === email.toLowerCase() && u.password === password,
  )
  if (success) {
    localStorage.setItem('currentUser', JSON.stringify(success))
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const currentUser = localStorage.getItem('currentUser')
  console.log('currentUser', currentUser)
  if (currentUser) {
    const response = JSON.parse(currentUser)
    console.log('response', response)
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...response,
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.removeItem('currentUser')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
